

.modal-backdrop {
    background-color: #4f4436;
}

.modal-content {
    border-radius: 10px;
}

.modal-header {
    border-radius: 10px 10px 0px 0px;
    background-color: #ff9d2b;
    display:block;
    padding: 0.2em;
}

.modal-header .modal-title {
    font-size: 1em;
    font-weight: bold;
    color: white;
    margin-left: 3%;
}

.modal-title-text{
    margin-top: 5px;
}

.modal-header .x-execute {
    background-color: #ff9d2b;
    color: white;
    border: none;
    margin-right: 2%;
}

.modal-header .x-execute:focus {
    background-color: #ff9d2b;
    color: #ffffff80; 
    outline:none;
    box-shadow: none;
}

.modal-body {
    padding-top: 10px;
    line-height: 1;
    max-height: 70vh;
    overflow-y: auto;
}

.modal-body .card {
    background-color: #eef3f9;
    border: none;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 15px;
}

.modal-body .card-header {
    background-color: white;
    border: none;
}

.modal-body .card-header .card-title {
    font-weight: bold;
    color: black;
}

.modal-body .card-body {
    background-color: #f7f2ed;
    padding: 1% 3% 3% 3%;
}

.modal-body .card-body .control-label {
    text-align: left;
    font-size: 1em;
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 1%;
    margin-left: -2%;
    margin-top: 2%;
}

.modal-body .card-body .user_name .control-label {
    font-weight: normal;
    margin-top: 0;
}

.modal-body .card-body .control-button {
    margin: 0px 2px 0px 2px;
    border: 1px solid #ccc;
    color: #333
}

.modal-body .card-body .control-button:hover, .modal-body .card-body .control-button:focus {
    background-color: #444;
    color: white !important;
    outline: none;
}

.control-button-modal {
    background-color: #808080;
    color: white;
    margin: 0px 2px 0px 2px;
}

.control-button-modal:hover, .control-button-modal:focus {
    background-color: #444;
    color: white;
    outline: none;
}

.modal-body .multiValueFormMinus:focus, .modal-body .multiValueFormPlus:focus, .multiValueFormToggle:focus{
    outline: none;
}

.modal-body .card-body .is-invalid .control-label {
    color:#C7241C;
}

.modal-body .card-body .is-invalid .css-1s2u09g-control {
    border-color: #C7241C;
}

.validation-message {
    color:#C7241C;
}

@media screen and (min-width: 992px) {
    .modal-body .card-body .control-label {
        text-indent: 10px;
    }
}

.modal-body .card-body .checkbox {
    padding-top: 0px;
}

.modal-body .card-body p.form-control-static {
    padding-top: 0px;
}

.modal-body .card-body .fa-icon {
    width: 17px;
    height: 17px;
}

.modal-body .card-body .fa-icon-static {
    margin-left: 10px;
}

.card-button {
    margin-right: auto;
}

.card-button .cancel-execute {
    margin-right: 15px;
    padding: 4px 20px;
}

.card-button .ok-execute {
    margin-right: 15px;
    padding: 4px 40px;
    background-color: #ff9d2b;
    outline: none;
    border: solid 1px #e67a00;
}

.card-button .ok-execute:hover {
    padding: 4px 40px;
    background-color: #e67a00;
}

.card-button .ok-execute:focus, .card-button .cancel-execute:focus{
    outline: none;
}

.card-button .align-left {
    flex: 1 0 auto;
}
.card-button .align-right {
    flex: 0 1 auto;
}

.card-button .custom-delete {
    margin-left: 15px;
    padding: 4px 20px;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
    z-index: 1;
}

.asc-block-ui .asc-block-icon-box {
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}

.over-width-modal {
    width: 60% !important;
    max-width: none !important;
}

.message-div {
    padding: 1% 1.5% 1% 1.5%;
}

.message-div.right {
    display: block;
    float: right;
    width: 100%;
    margin-bottom: 1%;
}

.voice-text-panel{
    scroll-behavior: smooth;
    height: 22vh;
    overflow-y: auto;
    border: ridge;
    background-color: #faf7f5;
}

.message-box {
    display: inline-block;
    border-radius: 5px;
    padding: 1.5%;
    padding-left: 2%;
    padding-right: 2%;
    min-width: 50%;
    max-width: 70%;
    background-color: #eee;
    box-shadow: 3px 3px 0px 0px #ccc;
}

.message-box.right{
    margin-right: 1.5%;
    background-color: #fff;
    float: right;
}

.message-box .user_name {
    margin-left: 0.8vw;
    font-weight: bold;
}

.message-box .voice-data{
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
}

.message-box .voice-time.right{
    color: #c71c1c;
}

.message-box .voice-time.left{
    color: #0e7edb;
}