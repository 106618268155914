.signIn-col{
    height: 100vh;
    background-color: white;
}

.signIn {
    max-width: 70%;
    margin: 0 auto;
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.signIn .signIn-button {
    width: 100%;
    margin-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #ff9d2b;
    color: white;
    float: right;
    border: 0px !important;
}

.signIn .input-group {
    margin-top: 5%;
}

.signIn .spinning {
    animation: spin 1s infinite linear;
    margin-left: 0%;
}