html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}

input[type=file] {
    width: 100%;
}

#root {
    height: 100%;    
}

@media screen and (min-width: 768px) {
    body {
        overflow: hidden;
    }
}

.LoadingButton .spinning{
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
.signIn-col{
    height: 100vh;
    background-color: white;
}

.signIn {
    max-width: 70%;
    margin: 0 auto;
    position: relative;
    top: 40%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.signIn .signIn-button {
    width: 100%;
    margin-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #ff9d2b;
    color: white;
    float: right;
    border: 0px !important;
}

.signIn .input-group {
    margin-top: 5%;
}

.signIn .spinning {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    margin-left: 0%;
}
.table-div{
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 0 1px #ddd;
    overflow-y: auto;
    overflow-x: auto;
}

.none-overflow{
    overflow: hidden;
}

.common-table {
    table-layout: fixed;
    text-align: center !important;
    z-index: 0 !important;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
}

.common-table tr, .common-table td, .common-table th{
    border: 1px solid #ddd;
}

.common-table td{
    padding: 2px;
}

.common-table thead{
    position: -webkit-sticky;
    position: sticky;
    background-color: #ff9d2b;
    top: 0;
    color: white;
    height: 30px !important;
}

.common-table tr.-even {
    background-color: white;
}

.common-table tbody{
    background-color: #ffffff !important;
}

tbody tr:nth-child(2n) {
    background-color: #faf5f0;
}

tbody tr:nth-child(2n+1) {
    background-color: #fff;
}

tbody tr:nth-child(n):hover {
    background-color: #f5ece4 !important;
}

.common-table .control-button {
    background-color: #808080;
    color: white !important;
    margin: 0 2px 0;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .control-button-disabled {
    background-color: #444444;
    color: #808080 !important;
    margin: 0 2px 0;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .control-button-disabled:hover, .common-table .control-button-disabled:focus {
    background-color: #333333;
    color: #808080;
    outline: none;
    margin: 0 2px 0;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.pagination {
    padding: 0.8%;
    background-color: #eee;
    text-align: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 #ddd
}

.pagination .page-btn{
    margin-left: 0.5%;
}
.pagination .page-span{
    margin-top: 0.3%;
    margin-left: 1%;
    margin-right: 1%;
}

.pagination .page-input{
    width: 50% !important;
    margin-right: 3px;
}

.no-data-text, .table-loading{
    padding: 5%;
    text-align: center !important;
}

.common-table .sub_user_plus-button {
    background-color: #ff9d2b;
    color: white !important;
    margin: 5px auto;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .sub_user_plus-button:hover, .common-table .sub_user_plus-button:focus {
    background-color: #e67a00;
}

.common-table .sub_user_row {
    margin: 0 0 5px;
}

.common-table .sub_user_row_head {
    margin: auto;
    height: auto;
}

.common-table .voice_3month_row_head {
    margin: auto;
    height: auto;
}

.common-table .sub_user_minus-button {
    background-color: #808080;
    color: white !important;
    margin: 5px auto;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .sub_user_minus-button:hover, .common-table .sub_user_minus-button:focus {
    background-color: #c5c5c5;
    color: white !important;
    margin: 5px auto;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.common-table .sub_user_minus-button-space {
    margin: auto;
    width: 28px;
    height: 28px;
}

.common-table .sub_user_password_change {
    display: flex;
}

.spinning {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    margin-left: 2000%;
}
@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}



.modal-backdrop {
    background-color: #4f4436;
}

.modal-content {
    border-radius: 10px;
}

.modal-header {
    border-radius: 10px 10px 0px 0px;
    background-color: #ff9d2b;
    display:block;
    padding: 0.2em;
}

.modal-header .modal-title {
    font-size: 1em;
    font-weight: bold;
    color: white;
    margin-left: 3%;
}

.modal-title-text{
    margin-top: 5px;
}

.modal-header .x-execute {
    background-color: #ff9d2b;
    color: white;
    border: none;
    margin-right: 2%;
}

.modal-header .x-execute:focus {
    background-color: #ff9d2b;
    color: #ffffff80; 
    outline:none;
    box-shadow: none;
}

.modal-body {
    padding-top: 10px;
    line-height: 1;
    max-height: 70vh;
    overflow-y: auto;
}

.modal-body .card {
    background-color: #eef3f9;
    border: none;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 15px;
}

.modal-body .card-header {
    background-color: white;
    border: none;
}

.modal-body .card-header .card-title {
    font-weight: bold;
    color: black;
}

.modal-body .card-body {
    background-color: #f7f2ed;
    padding: 1% 3% 3% 3%;
}

.modal-body .card-body .control-label {
    text-align: left;
    font-size: 1em;
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 1%;
    margin-left: -2%;
    margin-top: 2%;
}

.modal-body .card-body .user_name .control-label {
    font-weight: normal;
    margin-top: 0;
}

.modal-body .card-body .control-button {
    margin: 0px 2px 0px 2px;
    border: 1px solid #ccc;
    color: #333
}

.modal-body .card-body .control-button:hover, .modal-body .card-body .control-button:focus {
    background-color: #444;
    color: white !important;
    outline: none;
}

.control-button-modal {
    background-color: #808080;
    color: white;
    margin: 0px 2px 0px 2px;
}

.control-button-modal:hover, .control-button-modal:focus {
    background-color: #444;
    color: white;
    outline: none;
}

.modal-body .multiValueFormMinus:focus, .modal-body .multiValueFormPlus:focus, .multiValueFormToggle:focus{
    outline: none;
}

.modal-body .card-body .is-invalid .control-label {
    color:#C7241C;
}

.modal-body .card-body .is-invalid .css-1s2u09g-control {
    border-color: #C7241C;
}

.validation-message {
    color:#C7241C;
}

@media screen and (min-width: 992px) {
    .modal-body .card-body .control-label {
        text-indent: 10px;
    }
}

.modal-body .card-body .checkbox {
    padding-top: 0px;
}

.modal-body .card-body p.form-control-static {
    padding-top: 0px;
}

.modal-body .card-body .fa-icon {
    width: 17px;
    height: 17px;
}

.modal-body .card-body .fa-icon-static {
    margin-left: 10px;
}

.card-button {
    margin-right: auto;
}

.card-button .cancel-execute {
    margin-right: 15px;
    padding: 4px 20px;
}

.card-button .ok-execute {
    margin-right: 15px;
    padding: 4px 40px;
    background-color: #ff9d2b;
    outline: none;
    border: solid 1px #e67a00;
}

.card-button .ok-execute:hover {
    padding: 4px 40px;
    background-color: #e67a00;
}

.card-button .ok-execute:focus, .card-button .cancel-execute:focus{
    outline: none;
}

.card-button .align-left {
    flex: 1 0 auto;
}
.card-button .align-right {
    flex: 0 1 auto;
}

.card-button .custom-delete {
    margin-left: 15px;
    padding: 4px 20px;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
    z-index: 1;
}

.asc-block-ui .asc-block-icon-box {
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}

.over-width-modal {
    width: 60% !important;
    max-width: none !important;
}

.message-div {
    padding: 1% 1.5% 1% 1.5%;
}

.message-div.right {
    display: block;
    float: right;
    width: 100%;
    margin-bottom: 1%;
}

.voice-text-panel{
    scroll-behavior: smooth;
    height: 22vh;
    overflow-y: auto;
    border: ridge;
    background-color: #faf7f5;
}

.message-box {
    display: inline-block;
    border-radius: 5px;
    padding: 1.5%;
    padding-left: 2%;
    padding-right: 2%;
    min-width: 50%;
    max-width: 70%;
    background-color: #eee;
    box-shadow: 3px 3px 0px 0px #ccc;
}

.message-box.right{
    margin-right: 1.5%;
    background-color: #fff;
    float: right;
}

.message-box .user_name {
    margin-left: 0.8vw;
    font-weight: bold;
}

.message-box .voice-data{
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
}

.message-box .voice-time.right{
    color: #c71c1c;
}

.message-box .voice-time.left{
    color: #0e7edb;
}
.user_password_change-button {
    background-color: #ff9d2b;
    color: white !important;
    margin: 5px 5px 5px 5px;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.user_password_change-button:hover, .user_password_change-button:focus  {
    background-color: #e67a00;
}

.user_password_cancel-button {
    background-color: #808080;
    color: white !important;
    margin: 5px 5px 5px 5px;
    width: 28px;
    height: 28px;
    font-size: small;
    border-color: #808080;
}

.user_password_cancel-button:hover, .user_password_cancel-button:focus {
    background-color: #c5c5c5;
}

.user_password_change {
    display: flex;
}

.options-check-row {
    display: flex;
    margin-bottom: 5px;
}

.options-check-description {
    margin: 3px 5px 5px;
}

.Forbidden {
    padding-top: 100px;
    text-align: center;
}

.Header {
    margin: 0px;
    border: none !important;
    border-radius: 0px !important;
    background-color: white;
    text-align: center;
}

.popover {
    max-width: 100%;
}

.popover-title {
    text-align: center;
    font-weight: bold;
}

.logout-button-div {
    text-align: center;
}

.logout-button {
    padding: 4px 10px;
    width: 150px;
    background-color: #ff9d2b;
    outline: none;
    border: solid 1px #e67a00;
}

.logout-button:hover {
    background-color: #e67a00;
}

.logout-button:focus{
    outline: none;
}

.disabled {
    display: none;
}

a{
    color: #000 !important;
    text-decoration: none !important
}

a:hover, a:active {
    color: #ff9d2b !important;
}

.toggle-btn {
    margin-right: 1%;
}
.App {
    font-family: Meiryo, Meiryo-Italic, MeiryoUI, MeiryoUI-Italic, serif;
    height: 100%;
}

.App .container {
    height: 100%;
    padding: 0;
}
.signin-container{
    height: 100%;
}

.asc-display-none {
    display: none;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.asc-block-ui .asc-block-icon-box{
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}

.asc-block-ui-record .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui-record .asc-block-icon-box {
    position: fixed;
    top: 40%;
    /* bottom: 55%; */
    right: 60%;
    left: 50%;
}

.asc-block-ui-record .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #b8b8b8;
}
.signIn-logo-overview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: rgb(255, 213, 151);
}

.signIn-logo-string{
    text-align: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.img-logo {
    width: 30vw;
}

.git-version {
    font-size: 0.8em;
    position: fixed;
    bottom: 0;
    left: 5px;
    color: white;
    opacity: 0.8;
}

.table-button {
    margin: 0 10px 10px 0;
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 14px;
    color: white;
    background-color: #272d36;
}

.table-button:hover, .table-button:focus, .table-button:active, .table-button.click {
    background-color: black !important;
    color: #ffffff95 !important;
    outline: none !important;
}

.account-button{
    margin-top: 1%;
    float: right;
    padding: 4px 10px;
    width: 150px;
    outline: none;
}

.account-button:focus{
    outline: none;
}

.filter-input{
    margin-bottom: 2%;
    width: 96%;
    border: 1px solid #ddd;
}

.filter-input:focus{
    border: 1px solid #808080;
    outline: none;
}

.header-app {
    background-color: #eee;
}

.navbar-brand{
    margin-left: 1%;
}

@media screen and (max-width: 767px) {
    .header-nav {
        background-color: #ff9d2b;
        padding: 0;
    }
    .date-picker-frame {
        width: 45% !important;
    }
    .date-picker.form-control, .Record table{
        font-size: 0.75rem !important;
    }
    .call_time {
        font-weight: bold;
    }
    #record-search{
        margin-top: 1rem;
    }
    .record-message-count{
        margin-top: 1.3rem  !important;
    }
}

@media screen and (max-width:380px) {
    .date-picker.form-control{
        font-size: 0.6rem !important;
    }
}

.routes-app {
    overflow-y: auto;
    padding: 20px 30px;
    background-color: white;
}

.hover-black:hover {
    cursor: pointer;
    color: black !important;
}

.margin-right-05 {
    margin-right: 0.5em !important;
}

.margin-left-05 {
    margin-left: 0.5em !important;
}

.margin-top-03 {
    margin-top: 0.3em !important;
}

.margin-top-05 {
    margin-top: 0.5em !important;
}
.margin-top-07 {
    margin-top: 0.7em !important;
}
.z-index-0 {
    z-index: 0;
}

.z-index-1500 {
    z-index: 1500;
}

.header-nav-item {
    background-color: black;
}
.result-count{
    color: #ff9d2b;
    text-align: right;
}
.search-message{
    color: #C7241C;
}

.record-message, .record-message-count{
    font-size: 0.9em;
    font-weight: bold;
    color: #C7241C;
}

.record-message-count{
    margin-top: 0.5rem;
}

.select-width {
    min-width: 200px;
}

.container-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.modal-checked-message{
    margin-top: 10px;
    font-size: small;
}

.csvImportMsg{
    margin-top: 10px;
}

a.csv-format-download-link{
    display: block;
    margin: 25px 0 10px;
}

.ok-button-color{
    color: green;
}

.remove-button-color{
    background-color: #eee;
}

.default-button {
    padding: 4px 40px;
}

.ok-execute-btn {
    padding: 4px 40px;
    background-color: #0e7edb;
}

.ok-execute-btn:hover {
    background-color: #ff9d2b;
}

.cancel-execute-btn {
    margin-right: 15px;
    padding: 4px 20px;
}

.ok-execute-btn:focus, .cancel-execute-btn:focus{
    outline: none;
}
.label-color-333333 {
    color: #333333;
}

.extension-date-picker{
    width: 100%;
}

.fa-16 {
    font-size: 1.6em;
}

::-webkit-input-placeholder {
    color: #999;
    opacity: 1;
}

::placeholder {
    color: #999;
    opacity: 1;
}

.recorded-voice-audio {
    margin-top: 5px;
    width: 100%;
}

.searchBtn:disabled {
    background-color: #fab566;
    border-color: #fab566;
}

.searchBtn{
    background-color: #ff9d2b;
    border-color: #ff9d2b;
}

.record-inout-btn {
    background-color: white;
    color: #ff9d2b;
    border-color: #ff9d2b;
}

.record-inout-btn:hover, .record-inout-btn:active, .record-inout-btn:focus, .record-inout-btn.click, .record-inout-btn-focus, .searchBtn:hover, .searchBtn:active, .searchBtn:focus, .searchBtn.click {
    background-color: #e67a00;
    border-color: #e67a00;
    color: white;
}

.customerAddBtn, .customerAddBtn:hover, .customerAddBtn:active, .customerAddBtn:focus, .customerAddBtn.click {
    background-color: #ff9d2b;
    border-color: #ff9d2b;
    margin: 1%;
    padding: 0.1rem 0.4rem;
}

.customerDeleteBtn, .customerDeleteBtn:hover, .customerDeleteBtn:active, .customerDeleteBtn:focus, .customerDeleteBtn.click {
    background-color: #C7241C;
    border-color: #C7241C;
    padding: 4px 40px;
    outline: none;
}

.record-card.card-body{
    padding: 0.7rem 0.7rem !important;
}

.react-datepicker__close-icon::after, .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected{
    background-color: #ff9d2b !important;
}

.cancel-link {
    margin-top: 1%;
    float: left;
    padding: 4px 10px;
    width: 150px;
    text-decoration: underline !important;
    color: #7d7d7d !important;
}

.react-datepicker__close-icon {
    display: flex;
    align-items: center; /* 上下中央に配置 */
    /*justify-content: center; !* 左右中央に配置（必要に応じて） *!*/
    height: 100%; /* 親要素の高さに合わせる */
}

.react-datepicker__close-icon::before {
    content: '';
    display: inline-block;
    width: 20px; /* アイコンの幅 */
    height: 20px; /* アイコンの高さ */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" fill="%23cccccc"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
}

.react-datepicker__close-icon:hover::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" fill="%23999999"/></svg>');
}

.react-datepicker__close-icon::after {
    content: none; /* 既存のアイコンを非表示 */
}
