.App {
    font-family: Meiryo, Meiryo-Italic, MeiryoUI, MeiryoUI-Italic, serif;
    height: 100%;
}

.App .container {
    height: 100%;
    padding: 0;
}
.signin-container{
    height: 100%;
}

.asc-display-none {
    display: none;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.asc-block-ui .asc-block-icon-box{
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}

.asc-block-ui-record .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui-record .asc-block-icon-box {
    position: fixed;
    top: 40%;
    /* bottom: 55%; */
    right: 60%;
    left: 50%;
}

.asc-block-ui-record .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #b8b8b8;
}
.signIn-logo-overview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: rgb(255, 213, 151);
}

.signIn-logo-string{
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.img-logo {
    width: 30vw;
}

.git-version {
    font-size: 0.8em;
    position: fixed;
    bottom: 0;
    left: 5px;
    color: white;
    opacity: 0.8;
}

.table-button {
    margin: 0 10px 10px 0;
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 14px;
    color: white;
    background-color: #272d36;
}

.table-button:hover, .table-button:focus, .table-button:active, .table-button.click {
    background-color: black !important;
    color: #ffffff95 !important;
    outline: none !important;
}

.account-button{
    margin-top: 1%;
    float: right;
    padding: 4px 10px;
    width: 150px;
    outline: none;
}

.account-button:focus{
    outline: none;
}

.filter-input{
    margin-bottom: 2%;
    width: 96%;
    border: 1px solid #ddd;
}

.filter-input:focus{
    border: 1px solid #808080;
    outline: none;
}

.header-app {
    background-color: #eee;
}

.navbar-brand{
    margin-left: 1%;
}

@media screen and (max-width: 767px) {
    .header-nav {
        background-color: #ff9d2b;
        padding: 0;
    }
    .date-picker-frame {
        width: 45% !important;
    }
    .date-picker.form-control, .Record table{
        font-size: 0.75rem !important;
    }
    .call_time {
        font-weight: bold;
    }
    #record-search{
        margin-top: 1rem;
    }
    .record-message-count{
        margin-top: 1.3rem  !important;
    }
}

@media screen and (max-width:380px) {
    .date-picker.form-control{
        font-size: 0.6rem !important;
    }
}

.routes-app {
    overflow-y: auto;
    padding: 20px 30px;
    background-color: white;
}

.hover-black:hover {
    cursor: pointer;
    color: black !important;
}

.margin-right-05 {
    margin-right: 0.5em !important;
}

.margin-left-05 {
    margin-left: 0.5em !important;
}

.margin-top-03 {
    margin-top: 0.3em !important;
}

.margin-top-05 {
    margin-top: 0.5em !important;
}
.margin-top-07 {
    margin-top: 0.7em !important;
}
.z-index-0 {
    z-index: 0;
}

.z-index-1500 {
    z-index: 1500;
}

.header-nav-item {
    background-color: black;
}
.result-count{
    color: #ff9d2b;
    text-align: right;
}
.search-message{
    color: #C7241C;
}

.record-message, .record-message-count{
    font-size: 0.9em;
    font-weight: bold;
    color: #C7241C;
}

.record-message-count{
    margin-top: 0.5rem;
}

.select-width {
    min-width: 200px;
}

.container-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.modal-checked-message{
    margin-top: 10px;
    font-size: small;
}

.csvImportMsg{
    margin-top: 10px;
}

a.csv-format-download-link{
    display: block;
    margin: 25px 0 10px;
}

.ok-button-color{
    color: green;
}

.remove-button-color{
    background-color: #eee;
}

.default-button {
    padding: 4px 40px;
}

.ok-execute-btn {
    padding: 4px 40px;
    background-color: #0e7edb;
}

.ok-execute-btn:hover {
    background-color: #ff9d2b;
}

.cancel-execute-btn {
    margin-right: 15px;
    padding: 4px 20px;
}

.ok-execute-btn:focus, .cancel-execute-btn:focus{
    outline: none;
}
.label-color-333333 {
    color: #333333;
}

.extension-date-picker{
    width: 100%;
}

.fa-16 {
    font-size: 1.6em;
}

::placeholder {
    color: #999;
    opacity: 1;
}

.recorded-voice-audio {
    margin-top: 5px;
    width: 100%;
}

.searchBtn:disabled {
    background-color: #fab566;
    border-color: #fab566;
}

.searchBtn{
    background-color: #ff9d2b;
    border-color: #ff9d2b;
}

.record-inout-btn {
    background-color: white;
    color: #ff9d2b;
    border-color: #ff9d2b;
}

.record-inout-btn:hover, .record-inout-btn:active, .record-inout-btn:focus, .record-inout-btn.click, .record-inout-btn-focus, .searchBtn:hover, .searchBtn:active, .searchBtn:focus, .searchBtn.click {
    background-color: #e67a00;
    border-color: #e67a00;
    color: white;
}

.customerAddBtn, .customerAddBtn:hover, .customerAddBtn:active, .customerAddBtn:focus, .customerAddBtn.click {
    background-color: #ff9d2b;
    border-color: #ff9d2b;
    margin: 1%;
    padding: 0.1rem 0.4rem;
}

.customerDeleteBtn, .customerDeleteBtn:hover, .customerDeleteBtn:active, .customerDeleteBtn:focus, .customerDeleteBtn.click {
    background-color: #C7241C;
    border-color: #C7241C;
    padding: 4px 40px;
    outline: none;
}

.record-card.card-body{
    padding: 0.7rem 0.7rem !important;
}

.react-datepicker__close-icon::after, .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected{
    background-color: #ff9d2b !important;
}

.cancel-link {
    margin-top: 1%;
    float: left;
    padding: 4px 10px;
    width: 150px;
    text-decoration: underline !important;
    color: #7d7d7d !important;
}

.react-datepicker__close-icon {
    display: flex;
    align-items: center; /* 上下中央に配置 */
    /*justify-content: center; !* 左右中央に配置（必要に応じて） *!*/
    height: 100%; /* 親要素の高さに合わせる */
}

.react-datepicker__close-icon::before {
    content: '';
    display: inline-block;
    width: 20px; /* アイコンの幅 */
    height: 20px; /* アイコンの高さ */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" fill="%23cccccc"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
}

.react-datepicker__close-icon:hover::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" fill="%23999999"/></svg>');
}

.react-datepicker__close-icon::after {
    content: none; /* 既存のアイコンを非表示 */
}