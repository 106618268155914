.Header {
    margin: 0px;
    border: none !important;
    border-radius: 0px !important;
    background-color: white;
    text-align: center;
}

.popover {
    max-width: 100%;
}

.popover-title {
    text-align: center;
    font-weight: bold;
}

.logout-button-div {
    text-align: center;
}

.logout-button {
    padding: 4px 10px;
    width: 150px;
    background-color: #ff9d2b;
    outline: none;
    border: solid 1px #e67a00;
}

.logout-button:hover {
    background-color: #e67a00;
}

.logout-button:focus{
    outline: none;
}

.disabled {
    display: none;
}

a{
    color: #000 !important;
    text-decoration: none !important
}

a:hover, a:active {
    color: #ff9d2b !important;
}

.toggle-btn {
    margin-right: 1%;
}